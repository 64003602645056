<template>
  <div>
    <RegisterMethod />
  </div>
</template>

<script>
  import RegisterMethod from '@/components/methods/RegisterMethod.vue';

  export default {
    components: {
      RegisterMethod,
    }
  }
</script>